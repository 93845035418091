<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Return Codes"></page-header>
    <main class="p-6 flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <vue-good-table
          styleClass="vgt-table condensed"
          mode="remote"
          :rows="returnCodes"
          :columns="columns"
          :totalRows="totalCodes"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :pagination-options="{
            enabled: true,
            perPage: 50,
            perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
        />
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Alert = () => import("@/components/Alert.vue");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");

export default {
  components: {
    PageHeader,
    Alert,
    Spinner,
  },
  data() {
    return {
      isBusy: false,
      returnCodes: [],
      totalCodes: 0,
      totalPages: 0,
      currentPage: 0,
      pagingParams: {
        page: 1,
        limit: 50,
      },
      columns: [
        {
          label: "DISTRIBUTOR",
          field: "agent_name",
          width: "150px",
        },
        {
          label: "ENGINEER",
          field: "metadata.engineer_name",
        },
        {
          label: "DATE",
          field: "created_at",
          width: "150px",
          type: "date",
          dateInputFormat: "t",
          dateOutputFormat: "dd/MM/yyyy",
          // formatFn: this.formatBoolToYesNo
        },
        {
          label: "LEVEL",
          field: "code_level",
          width: "150px",
          // sortable: false,
          // formatFn: this.formatBoolToYesNo
        },
        {
          label: "CHALLENGE CODE",
          field: "challenge",
          width: "150px",
          // sortable: false,
          // formatFn: this.formatBoolToYesNo
        },
        {
          label: "RETURN CODE",
          field: "response",
          width: "150px",
          // sortable: false,
          // formatFn: this.formatBoolToYesNo
        },
        {
          label: "CHARGEABLE",
          field: "response",
          width: "150px",
          // sortable: false,
          // formatFn: this.formatBoolToYesNo
        },
      ],
    };
  },
  async mounted() {
    await this.loadCodes();
  },
  methods: {
    valueFormatter: function(params) {
      switch (params.colDef.field) {
        case "is_chargeable":
          return params.value ? "YES" : "NO";
        case "created_at":
          return this.$moment.unix(params.value).format("DD/MM/YYYY");
      }
    },
    loadCodes: async function() {
      this.isBusy = true;
      try {
        let result = await this.ReturnCodeService.getLoggedCodes(
          this.pagingParams
        );
        this.returnCodes = result.data;
        this.totalCodes = result.count;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },

    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      await this.loadCodes();
    },

    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage });
      await this.loadCodes();
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
      });
      await this.loadCodes();
    },

    updatePagingParams(newProps) {
      console.log("updateParams", newProps);
      this.pagingParams = Object.assign({}, this.pagingParams, newProps);
    },

    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },
  },
};
</script>
